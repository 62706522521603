'use client'

import type { ErrorPageContent } from '@/types/errorPageContent'

import { useSearchParams } from 'next/navigation'

const ErrorDescription = {
  WRONG_EMAIL: 'Wrong email or verification code.',
  MAX_ATTEMPTS: "You've reached the maximum number of attempts. Please try to login again.",
  UPDATED: 'Je account is bijgewerkt. Je moet opnieuw inloggen.',
  FORBIDDEN: 'Forbidden',
} as const

export const useRouteQueryErrors = (base?: Partial<ErrorPageContent>): ErrorPageContent => {
  let response: ErrorPageContent = {
    errorCode: '500',
    title: 'Oeps! Er ging iets mis.',
    description: '',
    buttonLabel: 'naar startpagina',
    buttonTo: '/',
  }

  const searchParams = useSearchParams()

  const errorDescription = searchParams.get('error_description')

  switch (errorDescription as (typeof ErrorDescription)[keyof typeof ErrorDescription]) {
    case ErrorDescription.WRONG_EMAIL:
    case ErrorDescription.MAX_ATTEMPTS:
      response = {
        title: 'Oeps, er is iets mis gegaan met inloggen...',
        description: 'De inloglink is verlopen. \n Log opnieuw in en ontvang een nieuwe link.',
        buttonLabel: 'Inloggen',
        buttonTo: '/api/auth/login',
      }
      break
    case ErrorDescription.UPDATED:
      response = {
        title: 'Account bijgewerkt',
        description:
          'Wij hebben je account bijgewerkt. \n Je moet opnieuw inloggen. \nExcuses voor het ongemak.',
        buttonLabel: 'Inloggen',
        buttonTo: '/api/auth/login',
      }
      break
    case ErrorDescription.FORBIDDEN:
      response = {
        title: 'Je bent niet ingelogd...',
        description: 'Je moet inloggen om hier verder te gaan.',
        buttonLabel: 'Inloggen',
        buttonTo: '/api/auth/login',
      }
      break
    default:
      break
  }

  return { ...response, ...base }
}
