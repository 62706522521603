'use client'

import type { RefObject } from 'react'

import { useCallback, useEffect } from 'react'

export type Position =
  | {
      init: false
    }
  | {
      init: true
      x: number
      y: number
      width: number
      height: number
      arrivedLeft: boolean
      arrivedRight: boolean
      arrivedTop: boolean
      arrivedBottom: boolean
    }

/**
 * Reactive scroll values for a react ref or a dom node
 *
 * @param target - dom node or react ref
 * @param callback - callback to run on scroll
 *
 * @see https://react-hooks-library.vercel.app/core/useScroll
 */
export function useScroll(target: RefObject<Element>, callback?: (pos: Position) => void) {
  const handleScrollChange = useCallback(() => {
    if (!target.current) return
    const x = target.current.scrollLeft
    const y = target.current.scrollTop
    const width = target.current.scrollWidth
    const height = target.current.scrollWidth
    const newPositions = {
      init: true,
      x,
      y,
      width,
      height,
      arrivedLeft: x === 0,
      arrivedRight: width - target.current.clientWidth - x <= 0,
      arrivedTop: y === 0,
      arrivedBottom: height - target.current.clientHeight - y <= 0,
    }
    callback?.(newPositions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, callback])

  useEffect(() => {
    if (!target.current) return () => {}
    const curTarget = target.current
    handleScrollChange()

    // Listen on resize
    const resizeObserver = new ResizeObserver(handleScrollChange)
    resizeObserver.observe(curTarget)

    // Listen on scroll
    curTarget.addEventListener('scroll', handleScrollChange)

    return () => {
      resizeObserver.disconnect()
      curTarget.removeEventListener('scroll', handleScrollChange)
    }
  }, [target])

  const scrollTo = (opts: ScrollToOptions) => {
    if (!target.current) return
    target.current.scrollTo({ behavior: 'smooth', ...opts })
  }

  return {
    scrollTo,
  }
}
