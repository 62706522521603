'use client'

import ErrorPage from '@/components/ErrorPage'

type Props = {
  error: Error & { digest?: string }
}

export default function Error({ error }: Props) {
  return <ErrorPage error={error} />
}
