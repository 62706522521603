'use client'

import type { ErrorPageContent } from '@/types'

import { Suspense, useEffect } from 'react'
import { usePathname } from 'next/navigation'
import * as Sentry from '@sentry/nextjs'

import { useRouteQueryErrors } from '@/hooks'
import ErrorHero from './ErrorHero'

type Props = (
  | {
      error: Error & { digest?: string }
      reportError?: true
    }
  | {
      error?: undefined
      reportError: false
    }
) &
  Partial<ErrorPageContent>

function FilledInHero(props: Partial<ErrorPageContent>) {
  return <ErrorHero {...useRouteQueryErrors(props)} />
}

export default function ErrorPage({ error, reportError = true, ...content }: Props) {
  const pathname = usePathname()

  useEffect(() => {
    if (reportError && error) {
      Sentry.captureException(error)
    }
  }, [error, pathname, reportError])

  return (
    <div className="flex min-h-screen flex-col items-center justify-between p-24">
      <Suspense>
        <FilledInHero {...content} />
      </Suspense>
      <div className="mb-auto" />
    </div>
  )
}
