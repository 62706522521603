'use client'

import type { ErrorPageContent } from '@/types/errorPageContent'

import { useState } from 'react'
import { Button } from '@eo/next-ui'

type Props = ErrorPageContent

export default function ErrorHero({
  title,
  description,
  buttonLabel = '',
  buttonTo = '',
  errorCode = '500',
}: Props) {
  const [disabled, setDisabled] = useState(false)
  return (
    <div className="mx-auto my-10 grid max-w-3xl justify-items-center px-5 md:my-32">
      <h1 className="text-center text-9xl font-extrabold tracking-widest text-purple-700">
        {errorCode}
      </h1>
      <p className="text-center text-xl font-bold">{title}</p>
      <p className="mt-4 text-center text-lg">{description}</p>
      {buttonLabel && buttonTo && (
        <div className="mt-4 w-full">
          <Button
            size="lg"
            outlined
            rounded
            label={buttonLabel}
            to={buttonTo}
            className="w-full"
            onClick={() => setDisabled(true)}
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  )
}
